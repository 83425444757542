import React from 'react'
import { GlobalStyles } from 'twin.macro'
import Header from '@/components/organisms/Header'
import Footer from '@/components/organisms/Footer'
import '@fontsource/kiwi-maru/300.css'
import '@fontsource/kiwi-maru/400.css'
import '@fontsource/kiwi-maru/500.css'

const Layout: React.FC = ({ children, ...rest }) => {
  return (
    <div
      {...rest}
      tw="font-family[游ゴシック, 游ゴシック体, YuGothic, 'Yu Gothic', メイリオ, sans-serif] text-gray-800 tracking-widest leading-snug"
    >
      <GlobalStyles />
      <Header />
      <main tw="bg-gray-100 lg:pb-12 pb-6">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
