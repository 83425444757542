import React, { useState } from 'react'
import { Link } from 'gatsby'
import HamburgerMenu from 'react-hamburger-menu'
import tw, { css } from 'twin.macro'
import { COLOR } from '@/shared/cssValue'
import ParagraphStyle from '../atoms/typo/ParagraphStyle'

const Header: React.FC = () => {
  const [isOpen, toggle] = useState(false)
  return (
    <header tw="fixed w-full top-0 z-50 bg-white shadow-md">
      <nav tw="flex items-center justify-between py-4 px-8 md:px-12">
        <div tw="flex justify-between items-center">
          <div tw="tracking-widest text-2xl font-bold text-gray-800 md:text-3xl">
            <a href="#" tw="font-family[Kiwi Maru]">
              LocalPlan
            </a>
          </div>
        </div>
        <HamburgerMenu
          isOpen={isOpen}
          menuClicked={() => toggle(!isOpen)}
          color={isOpen ? COLOR.white : COLOR.black}
          width={30}
          height={20}
          tw="z-50"
        />
        <nav
          css={css(
            isOpen ? tw`right-0` : tw`lg:-right-1/4 -right-3/4`,
            tw`absolute bg-gray-700 bg-opacity-80 lg:w-1/4 w-3/4 h-screen top-0 duration-500 flex justify-center flex-col items-center`,
          )}
        >
          <a href="#about" css={ParagraphStyle(tw`text-white block mt-4`)}>
            <span tw="font-family['Kiwi Maru']">LocalPlan</span>とは？
          </a>
          <a href="#services" css={ParagraphStyle(tw`text-white block mt-4`)}>
            <span tw="font-family['Kiwi Maru']">LocalPlan</span>の特徴
          </a>
          <a href="#merit" css={ParagraphStyle(tw`text-white block mt-4`)}>
            <span tw="font-family['Kiwi Maru']">LocalPlan</span>メリット
          </a>
          <a href="#voice" css={ParagraphStyle(tw`text-white block mt-4`)}>
            お客様の声
          </a>
          <a href="#example" css={ParagraphStyle(tw`text-white block mt-4`)}>
            サービス実施エリア
          </a>
          <a
            href="https://tamaplaza.localplan.app"
            target="_blank"
            css={ParagraphStyle(tw`text-white block mt-4`)}
          >
            TamaPlan
          </a>
        </nav>
      </nav>
    </header>
  )
}

export default Header
