import facepaint from 'facepaint'
export const COLOR = {
  white: '#FFF',
  black: '#3B4043',
  smoke: '#F5F5F5',
  gray: '#757575',
  neumo_bg: '#ebecf0',
}

export const FONTSIZE = {
  grand: '32px',
  large: '24px',
  regular: '18px',
  small: '14px',
  sp_grand: '28px',
  sp_large: '22px',
  sp_regular: '16px',
  sp_small: '12px',
}

export const PADDING = {
  header: '70px',
  section: '60px',
  headline: '40px',
  item: '30px',
  sp_header: '50px',
  sp_item: '20px',
}

export const InnerHeight =
  typeof window !== 'undefined' ? `${window.innerHeight}px` : '100vh'
export const SHADOW = '0 3px 6px 0 rgba(0, 0, 0, 0.15)'
export const ENGLISH_FONT =
  '"Kiwi Maru" ,游ゴシック, 游ゴシック体, YuGothic, "Yu Gothic", メイリオ, sans-serif'

const breakpoints = [576, 768, 992, 1200]
export const mediaQuery = facepaint(
  breakpoints.map((bp) => `@media(min-width: ${bp}px)`),
)
